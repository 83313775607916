import { dialCodeErrorMessage, phoneErrorMessage } from "../constant";

const errorOpening = "Please enter";
export const addNewError = (previousError, errorToAdd) => {
  /**
   * @param {string} previousError
   * @param {string} errorToAdd
   */
  const oldError = previousError.trim();
  const index = oldError.indexOf(errorToAdd);

  if (index === -1) {
    // check if old error is empty string
    if (oldError.length === 0) {
      return `${errorOpening} ${errorToAdd}`;
    }
    return `${oldError} and ${errorToAdd}`;
  }

  return oldError;
};

export const deleteError = (previousError, currentError) => {
  /**
   * @param {string} previous previous error states previous error value
   * @param {string} currentError current error states error to delete from old error
   */
  let oldError = previousError;
  const index = oldError.indexOf(currentError);
  oldError = oldError.trim();
  if (index === -1) {
    return oldError;
  }
  if (oldError === `${errorOpening} ${currentError}`) {
    return "";
  }
  if (currentError === phoneErrorMessage) {
    return `${errorOpening} ${dialCodeErrorMessage}`;
  }
  return `${errorOpening} ${phoneErrorMessage}`;
};
