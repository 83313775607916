const getCaptchaSiteKey = () => {
  let KEY = "6LfvRb4UAAAAAM6vbv9m4JE9jRcsmngJeF0wbiAo"; //Zopping KEY
  if (typeof window !== "undefined" && window) {
    if (window.location.host?.includes("zopping")) {
      KEY = "6LfvRb4UAAAAAM6vbv9m4JE9jRcsmngJeF0wbiAo"; //Zopping KEY
    } else {
      KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; //PUBLIC KEY
    }
  }
  return KEY;
};

export const CAPTCHA_SITE_KEY = getCaptchaSiteKey();
