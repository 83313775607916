/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./popup.css";

export default class Popup extends Component {
  componentDidMount() {
    if (this.props.show) {
      this.modalRef && this.modalRef.focus();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.modalRef && this.modalRef.focus();
    }
  }

  checkESC = (event) => {
    if (event.which === 27) {
      this.props.close();
    }
  };
  checkClick = (event) => {
    if (this.modalRef === event.target) {
      this.props.close();
    }
  };

  render() {
    const {
      props: { show, children },
    } = this;
    if (show) {
      return ReactDOM.createPortal(
        <div
          className="modal-backdrop"
          ref={(node) => {
            this.modalRef = node;
          }}
          onKeyDown={this.checkESC}
          onClick={this.checkClick}
          tabIndex="0"
        >
          {children}
        </div>,
        document.getElementById("popup")
      );
    }
    return null;
  }
}
