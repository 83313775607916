/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
import loadable from "@loadable/component";
import { navigate } from "@reach/router";
import React, { useState } from "react";

import Cross from "../../images/Cross.svg";
import TickCheckbox from "../../images/tick-icon-white.svg";
import { Input, Message } from "../../lib/getMessages";
import { saveSession } from "../../lib/session";
import "./loginForm.css";

const Login = loadable(() => import("../GoogleLogin"));
const PhoneNumber = loadable(() => import("../PhoneNumberUI"));
const HidePassword = loadable(() => import("../../icons/HidePassword.jsx"));
const ShowPassword = loadable(() => import("../../icons/ShowPassword.jsx"));

// TODO: Implement getMessage like function so as to pass translated text in props as well
const EMAIL_ERR_MSG = <Message dictKey={"email.error.msg"} />;

const LoginForm = ({
  close,
  setIsLoggedIn,
  showSignup,
  showForgotPasswordForm,
  registeredError,
  emailOrPhone,
  isDirectionRTL,
}) => {
  const [username, setUsername] = useState(emailOrPhone);
  const [validationError, setValidationError] = useState(false);
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const emailPattern =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const handleChangeInput = ({ target }) => {
    const { name, value, checked, error } = target;
    switch (name) {
      case "username":
        if (Number(value)) {
          setUsername({ dialCode: "+91", phoneNumber: value });
          if (!error) setValidationError(false);
        } else if (typeof value === "object") {
          if (!value.phoneNumber || Number(value.phoneNumber)) {
            setUsername(value);
            if (!error) setValidationError(false);
          } else setUsername(value.phoneNumber);
        } else {
          if (!emailPattern.test(value)) {
            setValidationError(true);
          } else setValidationError(false);
          setUsername(value);
        }
        break;
      case "password":
        setPassword(value);
        break;
      case "remember":
        setRemember(checked);
        break;
      default:
        break;
    }
  };

  const handlePhoneChange = (phoneData) => {
    if (phoneData?.error) {
      setValidationError(true);
    }
    handleChangeInput({
      target: {
        name: "username",
        error: phoneData.error,
        value: phoneData,
      },
    });
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    const url =
      window.location.host.includes("zopping") &&
      !window.location.host.includes("staging")
        ? "https://zopping.com/api/account-service/auth"
        : "https://staging.zopping.com/api/account-service/auth";

    const formData = new FormData();
    let formDataUsername = username;
    if (typeof username === "object") {
      formDataUsername = `${username.dialCode}${username.phoneNumber}`;
    }
    formData.append("username", formDataUsername);
    formData.append("password", password);
    formData.append("remember", remember);

    setSubmitting(true);

    if (username && password) {
      window
        .fetch(url, {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then((response) => {
          if (response.code === 200 || response.code === 201) {
            let token = response?.data?.user?.accessToken;
            delete response?.data?.user?.accessToken;
            saveSession({
              token: token,
              organization: response?.data?.organization,
              user: response?.data?.user,
            });
            setIsLoggedIn(true);
            setSubmitting(false);
            close();
            const methodType = username?.includes("@") ? "Email" : "Phone";
            window.open("/admin/home/dashboard", "_blank");

            window.gtag("event", "login", {
              remember_me: formData.remember,
              method: methodType,
            });
          } else {
            console.error(response);
            const errorMsg =
              response?.message?.split?.(":")?.[1] ||
              response?.message ||
              "Some error occurred";
            setError(errorMsg);
            setSubmitting(false);
          }
        });
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const PasswordIcon = showPassword ? ShowPassword : HidePassword;

  return (
    <div className="login-form-container">
      <div className="form-close-wrp" onClick={close}>
        <img src={Cross} alt="close form" />
      </div>

      <h1 className="login-form-heading">
        <Message
          dictKey={registeredError ? "registered.user.text" : "zopping.welcome"}
        />
      </h1>
      <p className="login-form-subheading">
        <Message dictKey="zopping.loginForm.subheading" />
      </p>
      <hr className="login-form-hr" />

      {error && <p className="login-form-error">{error}</p>}

      <form onSubmit={handleSubmitLogin}>
        <label className="login-form-label" htmlFor="username">
          <Message dictKey="heroFormInputPlaceholder" />*
        </label>
        {typeof username === "object" ? (
          <PhoneNumber
            autoFocus
            {...username}
            handleAddPhoneData={handlePhoneChange}
            isDirectionRTL={isDirectionRTL}
          />
        ) : (
          <Input
            autoFocus
            className="login-form-input"
            type="text"
            name="username"
            placeholderKey="loginForm.username.placeholder"
            value={username}
            title={EMAIL_ERR_MSG}
            onChange={handleChangeInput}
            required
          />
        )}
        {validationError && !(typeof username === "object") && (
          <div className="signup-button-error">
            <Message dictKey={"email.error.msg"} />
          </div>
        )}

        <label className="login-form-label" htmlFor="password">
          <Message dictKey="password" />*
        </label>
        <div className="password-wrp">
          <Input
            autoFocus
            className="login-form-input"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholderKey="loginForm.password.placeholder"
            value={password}
            onChange={handleChangeInput}
            required
          />
          <div className="password-view" onClick={(e) => handleShowPassword(e)}>
            <PasswordIcon />
          </div>
        </div>
        <div className="field cf">
          <label
            className={`checkbox-label ${
              isDirectionRTL && "checkbox-label-rtl"
            }`}
          >
            <input
              id="remember"
              type="checkbox"
              name="remember"
              className="remember-me-checkbox"
              checked={remember}
              onChange={handleChangeInput}
            />
            <span className="remember-checkbox-span">
              <img className="checkbox-tick" alt="" src={TickCheckbox} />
            </span>
          </label>
          <label htmlFor="remember" className="inline remember-me-label">
            <Message dictKey="remember.me" />
          </label>
          <div
            className={`forgot-link ${isDirectionRTL && "forgot-link-rtl"}`}
            onClick={() => {
              close();
              showForgotPasswordForm();
              window.gtag("event", "button_click", {
                category: "Link",
                page: "Login",
                label: "Forgot Password",
              });
            }}
          >
            <Message dictKey="forgot.password" />
          </div>
        </div>
        <button
          className="login-btn"
          type="submit"
          disabled={!username || !password || submitting || validationError}
        >
          {submitting ? (
            <Message dictKey="signing.in" />
          ) : (
            <Message dictKey="signin" />
          )}
        </button>
        <div className="login-option">
          <Message dictKey="login.or.text" />
          <Login
            handleLogin={handleSubmitLogin}
            setIsLoggedIn={setIsLoggedIn}
            close={close}
          />
        </div>
      </form>

      <div className="login-form-create-account">
        <Message dictKey="dont.have.account" />?
        <span
          onClick={() => {
            close();
            showSignup();
            window.gtag("event", "button_click", {
              category: "Link",
              page: "Login",
              label: "Create Account",
            });
          }}
          className="create-account-link"
        >
          <Message dictKey="create.account" />
        </span>
      </div>
    </div>
  );
};

export default LoginForm;
