/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
import loadable from "@loadable/component";
import { navigate } from "@reach/router";
import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../utils/Constants";
import Cross from "../../images/Cross.svg";
import { Input, Message } from "../../lib/getMessages";
import "./styles.css";
import { get } from "../../lib/storage";
import Loader from "../../components/Loader";
import axios from "axios";
const Login = loadable(() => import("../GoogleLogin"));
const PhoneNumber = loadable(() => import("../PhoneNumberUI"));

const EMAIL_ERR_MSG = <Message dictKey={"email.error.msg"} />;
// TODO: Implement getMessage like function so as to pass translated text in props as well
const SignupForm = ({
  close,
  showLogin,
  setIsLoggedIn,
  userCountryCode,
  isDirectionRTL,
}) => {
  const [username, setUsername] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [name, setName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const emailPattern =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const [countryCode, setCountryCode] = useState(null);
  const [loading, setLoading] = useState(true);

  const captchaRef = useRef();
  const [captchaResponse, setCaptchaResponse] = useState(null);

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const { country_code } = response?.data;
        setCountryCode(country_code);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user country:", error);

        setLoading(false);
      });
  }, []);

  const handleChangeInput = ({ target }) => {
    const { name, value, error } = target;
    switch (name) {
      case "username":
        if (Number(value)) {
          setUsername({ dialCode: "+91", phoneNumber: value });
          if (!error) setValidationError(false);
        } else if (typeof value === "object") {
          if (!value.phoneNumber || Number(value.phoneNumber)) {
            setUsername(value);
            if (!error) setValidationError(false);
          } else setUsername(value.phoneNumber);
        } else {
          if (!emailPattern.test(value)) {
            setValidationError(true);
          } else setValidationError(false);
          setUsername(value);
        }
        break;
      case "name":
        setName(value);
        break;
      default:
        break;
    }
  };

  const handlePhoneChange = (phoneData) => {
    if (phoneData?.error) {
      setValidationError(true);
    }
    handleChangeInput({
      target: {
        name: "username",
        error: phoneData.error,
        value: phoneData,
      },
    });
  };

  const handleSubmitSignup = (e) => {
    e.preventDefault();
    const url =
      window.location.host.includes("zopping") &&
      !window.location.host.includes("staging")
        ? "https://zopping.com/api/account-service/me"
        : "https://staging.zopping.com/api/account-service/me";

    const formData = {};
    if (typeof username === "object") {
      const formDataPhone = `${username.dialCode}${username.phoneNumber}`;
      formData.phone = formDataPhone;
    } else {
      formData.email = username;
    }
    if (name) formData.name = name;

    formData["g-recaptcha-response"] = captchaResponse;
    formData.metaData = JSON.parse(get("referralInfo"));

    setSubmitting(true);

    if (username) {
      window
        .fetch(url, {
          method: "POST",
          body: JSON.stringify(formData),
        })
        .then((response) => response.json())
        .then((response) => {
          if (response.code === 200 || response.code === 201) {
            const username =
              response.data.user.emails?.[0]?.email ||
              response.data.user.phones?.[0]?.phone;
            setSubmitting(false);
            close();
            const methodType = response.data.user.emails?.[0]?.email
              ? "Email"
              : "Phone";
            window.gtag("event", "sign_up", {
              method: methodType,
            });
            window.open(
              `/admin/account-created?username=${username}`,
              "_blank"
            );
          } else {
            console.error(response);
            if (captchaRef.current) {
              captchaRef.current.reset();
            }
            const errorMsg =
              response?.message?.split?.(":")?.[1] ||
              response?.message ||
              "Some error occurred";
            setError(errorMsg);
            setSubmitting(false);
          }
        });
    }
  };

  const handleCaptcha = (captcha) => {
    setCaptchaResponse(captcha);
  };
  const excludedCountries = ["IN", "GB"];
  const countryNotSupportedPhone =
    countryCode && !excludedCountries.includes(countryCode);
  return (
    <div className="login-form-container">
      <div className="form-close-wrp" onClick={close}>
        <img src={Cross} alt="close form" />
      </div>

      <h1 className="login-form-heading">
        <Message dictKey="create.your.account" />
      </h1>
      <p className="login-form-subheading">
        <Message dictKey="zopping.loginForm.subheading" />
      </p>
      <hr className="login-form-hr" />

      {error && <p className="login-form-error">{error}</p>}

      {!countryCode && loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmitSignup}>
          <label className="login-form-label" htmlFor="name">
            <Message dictKey="name" />
          </label>
          <Input
            className="login-form-input"
            type="text"
            name="name"
            placeholderKey="enter.name"
            spellcheck="false"
            value={name}
            onChange={handleChangeInput}
          />

          <label className="login-form-label" htmlFor="username">
            {countryNotSupportedPhone ? (
              <Message dictKey="heroFormEmailIdPlaceholder" />
            ) : (
              <Message dictKey="heroFormPhoneNumberPlaceholder" />
            )}
            *
          </label>
          {countryNotSupportedPhone ? (
            <Input
              autoFocus
              className="login-form-input"
              type="text"
              name="username"
              placeholderKey="loginForm.email.id.placeholder"
              spellcheck="false"
              value={username}
              title={EMAIL_ERR_MSG}
              onChange={handleChangeInput}
              required
            />
          ) : (
            <PhoneNumber
              {...username}
              handleAddPhoneData={handlePhoneChange}
              placeholderKey="enter.phone.number.placeholder"
              isDirectionRTL={isDirectionRTL}
            />
          )}
          <div className="sent-message">
            {countryNotSupportedPhone ? (
              <Message dictKey="signup-sent-email-message" />
            ) : (
              <Message dictKey="signup-sent-message" />
            )}
          </div>
          <div className="captcha-field">
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={CAPTCHA_SITE_KEY}
              onChange={handleCaptcha}
              onExpired={() => handleCaptcha(null)}
            />
          </div>
          {validationError && !(typeof username === "object") && (
            <div className="signup-button-error">
              <Message dictKey={"email.error.msg"} />
            </div>
          )}
          <button
            className="login-btn"
            type="submit"
            disabled={
              !username ||
              !name ||
              submitting ||
              validationError ||
              !captchaResponse
            }
          >
            {submitting ? (
              <Message dictKey="creating.account" />
            ) : (
              <Message dictKey="signup" />
            )}
          </button>
          {countryNotSupportedPhone && (
            <div className="login-option">
              <Message dictKey="login.or.text" />
              <Login
                setIsLoggedIn={setIsLoggedIn}
                close={close}
                buttonText={"signup.with.google"}
              />
            </div>
          )}
        </form>
      )}

      <div className="login-form-create-account">
        <Message dictKey="already.have.account" />?
        <span
          onClick={() => {
            close();
            showLogin();
            window.gtag("event", "button_click", {
              category: "Link",
              page: "Signup",
              label: "Login",
            });
          }}
          className="create-account-link"
        >
          <Message dictKey="signin" />
        </span>
      </div>
    </div>
  );
};

export default SignupForm;
