import React from "react";

export const DropDownArrow = () => {
  return (
    <svg
      width="9px"
      height="6px"
      viewBox="0 0 10 5"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Path-8</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="Marketing_promotions_add_type"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1183.000000, -196.000000)"
      >
        <g
          id="Group-11"
          transform="translate(370.000000, 98.000000)"
          fill="#80959D"
          fillRule="nonzero"
        >
          <g id="Group-10" transform="translate(30.000000, 80.000000)">
            <g id="arrow" transform="translate(783.000000, 18.000000)">
              <polygon id="Path-8" points="0 0 5 5 10 0" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
