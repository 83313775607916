/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import twemoji from "twemoji";
import Clear from "../../../../icons/Clear";
import Search from "../../../../icons/Search";
import "./phonePopupStyle.css";

export default class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchItem: "",
    };
  }
  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevProps.active !== this.props.active ||
      prevState.searchItem !== this.state.searchItem
    ) {
      return true;
    }
    return false;
  }

  setSearchItem = (val) => {
    this.setState({ searchItem: val });
  };

  handleSearchItem = (e) => {
    this.setSearchItem(e.target.value);
  };

  render() {
    const {
      phoneData,
      phoneDataName,
      active,
      handleSetDialCodeAndCountryCode,
      isDirectionRTL,
    } = this.props;
    const { searchItem } = this.state;
    let index = 0;
    return (
      <div
        className={[
          `popup-wrp  ${isDirectionRTL && "popup-wrp-rtl"}`,
          active ? "popup-wrp-active" : "",
        ].join(" ")}
      >
        <>
          <div className={`top-bubble ${isDirectionRTL && "top-bubble-rtl"}`} />
          <div className="country-wrp">
            <div className="search-container" ref={this.props.inputRef}>
              <Search className="search-icon" />
              <input
                type="text"
                name="search-item"
                value={searchItem}
                placeholder="enter name"
                className={`search-bar ${isDirectionRTL && "search-bar-rtl"}`}
                onChange={this.handleSearchItem}
                autoComplete="off"
              />
              {searchItem && (
                <Clear
                  className="close-search-icon"
                  onClick={() => this.setSearchItem("")}
                />
              )}
            </div>

            <div
              className={`country-items ${
                isDirectionRTL && "country-items-rtl"
              }`}
            >
              {Object.keys(phoneData.default).map((dialCode) => {
                const emojiArray = phoneData.default[dialCode];
                const nameArray = phoneDataName[dialCode]?.name;
                return emojiArray
                  .filter((_, indexEmojiArr) => {
                    const name = nameArray?.[indexEmojiArr] || "";
                    return (
                      searchItem === "" ||
                      dialCode.includes(searchItem.toLowerCase()) ||
                      name.toLowerCase().includes(searchItem.toLowerCase())
                    );
                  })
                  .map((element) => {
                    index += 1;
                    return (
                      <div
                        className="country-item"
                        key={index}
                        onClick={() =>
                          handleSetDialCodeAndCountryCode(dialCode, element)
                        }
                      >
                        <div
                          className="country-flag"
                          dangerouslySetInnerHTML={{
                            __html: twemoji.parse(element, {
                              base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
                            }),
                          }}
                        />
                        <div
                          className={`country-code ${
                            isDirectionRTL && "country-code-rtl"
                          }`}
                        >
                          {dialCode}
                        </div>
                      </div>
                    );
                  });
              })}
            </div>
          </div>
        </>
      </div>
    );
  }
}
