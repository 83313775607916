const getAllPermissions = function () {
  let permissions = JSON.parse(window.localStorage.getItem("permissions"));
  if (!permissions) return {};

  return Object.assign(
    {},
    ...Object.keys(permissions).map((endpoint) => ({
      [endpoint]: permissions[endpoint]["allowedMethods"],
    }))
  );
};

const savePermissions = function (permissions = []) {
  let formatted = Object.assign(
    {},
    ...permissions.map((permission) => {
      return {
        [permission.url]: permission,
      };
    })
  );
  window.localStorage.setItem("permissions", JSON.stringify(formatted));
  let formattedPermissions = getAllPermissions();
  window.localStorage.setItem(
    "formattedPermissions",
    JSON.stringify(formattedPermissions)
  );
};

export const saveSession = function (sessionData) {
  for (let key in sessionData) {
    if (sessionData[key]) {
      if (key === "user") {
        savePermissions(sessionData[key].endpointPermissions);
        delete sessionData[key].endpointPermissions;
      }
      window.localStorage.setItem(
        key,
        typeof sessionData[key] === "object"
          ? JSON.stringify(sessionData[key])
          : sessionData[key]
      );
    }
  }
};

export const clearSession = function (sessionData) {
  let organizationName = window.localStorage.getItem("organization-name");
  let language = window.localStorage.getItem("language");
  window.localStorage.clear();
  if (organizationName && organizationName !== "null") {
    window.localStorage.setItem("organization-name", organizationName);
  }
  if (language) {
    window.localStorage.setItem("language", language);
  }
};
