/* eslint-disable jsx-a11y/no-autofocus */
import { phone } from "phone";
import React, { useContext } from "react";
import fetchTimeout from "../../utils/fetchTimeout";
import FlagEmoji from "./components/FlagEmoji/FlagEmoji";
import { dialCodeErrorMessage, phoneErrorMessage } from "./constant";
import * as phoneData from "./phoneData.json";
import * as phoneDataName from "./phoneDataName.json";
import "./phoneNumberStyle.css";
import { addNewError, deleteError } from "./utils";
import { LanguageContext } from "../../Providers";
import en from "../../languages/en";

const IPDataUrl = "https://ipapi.co/json/";

function PhoneNumberComponent({ placeholderKey, ...props }) {
  const { dictionary } = useContext(LanguageContext);
  return (
    <PhoneNumber
      placeholder={
        dictionary?.[placeholderKey] || en?.[placeholderKey] || placeholderKey
      }
      {...props}
    />
  );
}

class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    // make component state as single source of truth
    const { dialCode } = this.props;
    const { phoneNumber } = this.props;
    let emoji = phoneData.default[dialCode];
    emoji = emoji ? emoji[0] : phoneData.default["+91"][0];

    this.state = {
      phoneNumber: phoneNumber || "",
      dialCode: dialCode || "+91",
      emoji,
      errorMessage: "",
      toggle: false,
    };
    this.phoneNumberRef = React.createRef();
    this.dialCodeRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false;
    // check if component Update is necessary or not;
    Object.keys(nextState).forEach((key) => {
      if (this.state[key] !== nextState[key]) shouldUpdate = true;
    });
    Object.keys(nextProps).forEach((key) => {
      if (
        nextProps[key] !== this.props[key] &&
        typeof nextProps[key] !== "function"
      )
        shouldUpdate = true;
    });
    return shouldUpdate;
  }
  updateCountryCode = (isoCode) => {
    if (!isoCode) return;

    const filteredData = Object.entries(phoneDataName).filter(([key, item]) => {
      const { code = [] } = item;
      const codeFound = code.some((val) => {
        return val.toLowerCase() === isoCode.toLowerCase();
      });
      if (codeFound === true) {
        return true;
      }
      return false;
    });
    if (Array.isArray(filteredData) && filteredData?.length > 0) {
      const dialCode = filteredData?.[0]?.[0];
      const emojiIndex = filteredData?.[0]?.[1]?.code?.indexOf(isoCode);
      const emoji = phoneData?.[dialCode]?.[emojiIndex];
      if (emoji) {
        this.handleSetDialCodeAndCountryCode(dialCode, emoji);
      }
    }
  };
  handleIp = () => {
    const controller = new AbortController();
    try {
      fetchTimeout(IPDataUrl, 5000, {
        signal: controller.signal,
      }).then((data) => {
        data
          .json()
          .then((readableData) => {
            const countryIsoCode = readableData?.country_code;
            if (countryIsoCode !== "IN") {
              this.updateCountryCode(countryIsoCode);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    } catch (err) {
      console.log(err);
    }
  };
  componentDidMount() {
    this.handleIp();
  }
  componentDidUpdate(prevProps) {
    let isUpdateNeeded = false;
    // synchronize the Dom Element with VDom.
    Object.keys(prevProps).forEach((key) => {
      if (
        prevProps[key] !== this.props[key] &&
        typeof this.props[key] !== "function"
      ) {
        isUpdateNeeded = true;
      }
    });
    if (isUpdateNeeded) {
      const { dialCode, phoneNumber, error } = this.props;
      let emoji = phoneData.default[dialCode];
      emoji = emoji ? emoji[0] : phoneData.default["+91"][0];
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => ({
        dialCode,
        phoneNumber,
        emoji,
        toggle: false,
        error,
      }));
    }
  }

  extractCountryCode = (phoneNumber) => {
    const Length = phoneNumber.length;
    let countryCode = "";
    let newCountryCode = "";
    for (let iter = 0; iter < Math.min(Length, 6); iter += 1) {
      countryCode += phoneNumber[iter];
      if (phoneData.default[countryCode]) {
        newCountryCode = countryCode;
        break;
      }
    }
    return newCountryCode;
  };

  handleOnChange = (event) => {
    if (event.preventDefault) event.preventDefault();
    if (event.stopPropagation) event.stopPropagation();
    const { value } = event.target;
    let { emoji, dialCode, errorMessage } = this.state;
    let newDialCode = "";

    if (value.startsWith("+") && value.length > 1) {
      newDialCode = this.extractCountryCode(value);
    }

    if (newDialCode) {
      // eslint-disable-next-line prefer-destructuring
      emoji = phoneData.default[newDialCode][0];
      dialCode = newDialCode;
    }

    let isValidPhoneNumber = false;
    isValidPhoneNumber = this.isValidPhoneNumber(value, dialCode);
    if (!isValidPhoneNumber) {
      errorMessage = addNewError(this.state.errorMessage, phoneErrorMessage);
    } else {
      errorMessage = deleteError(this.state.errorMessage, phoneErrorMessage);
    }
    // set the new State

    this.setState(() => ({
      phoneNumber: value,
      emoji,
      dialCode,
      errorMessage,
    }));
    this.handleOnInputSave(dialCode, value, errorMessage);
  };

  handleOnBlur = (e) => {
    const { value } = e.target;
    let { emoji, phoneNumber, errorMessage, dialCode } = this.state;
    let isValidPhoneNumber = false;
    const index = value.indexOf(dialCode);
    let newDialCode = "";
    if (value.startsWith("+") && index === -1) {
      newDialCode = this.extractCountryCode(value);
    }

    if (newDialCode) {
      // eslint-disable-next-line prefer-destructuring
      emoji = phoneData.default[newDialCode][0];
      dialCode = newDialCode;
    }

    isValidPhoneNumber = this.isValidPhoneNumber(value, dialCode);
    phoneNumber = value;
    if (!isValidPhoneNumber) {
      errorMessage = addNewError(this.state.errorMessage, phoneErrorMessage);
    } else {
      errorMessage = deleteError(this.state.errorMessage, phoneErrorMessage);
    }

    this.setState(() => ({
      phoneNumber,
      errorMessage,
      emoji,
      toggle: false,
    }));
    this.handleOnInputSave(dialCode, phoneNumber, errorMessage);
  };

  isValidPhoneNumber = (value, dialCode) => {
    const code = phoneDataName?.[dialCode]?.code?.[0] || "IN";
    const validationData = phone(`${value}`, { country: code });
    const { phoneNumber, countryCode, isValid } = validationData;
    let phoneNumberWithoutDialCode = (phoneNumber || "").substring(
      (countryCode || "").length
    );
    if (isValid && phoneNumberWithoutDialCode === value) return true;
    return false;
  };

  handleOnInputSave = (dialCode, phoneNumber, error) => {
    const { handleAddPhoneData } = this.props;
    let newPhoneNumber = phoneNumber;
    if (phoneNumber.indexOf(dialCode) !== -1) {
      newPhoneNumber = phoneNumber.substr(dialCode.length);
    }
    if (typeof handleAddPhoneData === "function") {
      handleAddPhoneData({
        dialCode,
        phoneNumber: newPhoneNumber,
        error,
      });
    }
  };

  handleSetDialCodeAndCountryCode = (newDialCode, emoji) => {
    const newError = deleteError(this.state.errorMessage, dialCodeErrorMessage);
    const { phoneNumber, dialCode } = this.state;
    let newPhoneNumber = phoneNumber;
    const index = phoneNumber.indexOf(dialCode);
    if (phoneNumber.startsWith("+") && index !== -1) {
      newPhoneNumber = phoneNumber.substr(dialCode.length);
    }

    this.setState(() => ({
      phoneNumber: newPhoneNumber,
      errorMessage: newError,
      emoji,
      dialCode: newDialCode,
      toggle: false,
    }));
    this.phoneNumberRef.current.focus();
    const isValidPhoneNumber = this.isValidPhoneNumber(phoneNumber, dialCode);
    if (isValidPhoneNumber)
      this.handleOnInputSave(dialCode, phoneNumber, newError);
  };

  getFormattedValue = (value) => {
    const { dialCode } = this.state;
    if (
      value.indexOf(dialCode) === 0 &&
      value.indexOf(" ") !== dialCode.length
    ) {
      return `${dialCode} ${value.substr(dialCode.length)}`;
    }
    return value;
  };

  render() {
    const {
      disableError,
      placeholder,
      fromHomePage,
      autoFocus,
      isDirectionRTL,
    } = this.props;
    const { emoji, errorMessage, phoneNumber } = this.state;
    return (
      <div
        className={[
          "phone-comp-wrp",
          fromHomePage ? "phone-comp-max-width" : "",
        ].join(" ")}
      >
        <div className="flag-phone-wrp">
          <FlagEmoji
            emoji={emoji}
            handleSetDialCodeAndCountryCode={
              this.handleSetDialCodeAndCountryCode
            }
            phoneData={phoneData}
            phoneDataName={phoneDataName}
            isDirectionRTL={isDirectionRTL}
          />
          <input
            className={`phone-number ${
              isDirectionRTL && "phone-number-rtl"
            }`}
            ref={this.phoneNumberRef}
            onBlur={this.handleOnBlur}
            value={phoneNumber}
            onChange={this.handleOnChange}
            type="tel"
            name="phoneNumber"
            placeholder={placeholder}
            autoComplete="off"
            required
            autoFocus={autoFocus}
          />
        </div>
        {errorMessage && !disableError ? (
          <div className={`error-message ${isDirectionRTL && "rtl-support"}`}>
            {errorMessage}
          </div>
        ) : null}
      </div>
    );
  }
}
export default PhoneNumberComponent;
