/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef } from "react";
import Cross from "../../images/Cross.svg";
import { Input, Message } from "../../lib/getMessages";
import PhoneNumber from "../PhoneNumberUI";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../utils/Constants";

// TODO: Implement getMessage like function so as to pass translated text in props as well
const EMAIL_ERR_MSG = <Message dictKey={"email.error.msg"} />;

const ResetPasswordForm = ({
  close,
  setIsLoggedIn,
  showLogin,
  isDirectionRTL,
}) => {
  const [username, setUsername] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const emailPattern =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const captchaRef = useRef();
  const [captchaResponse, setCaptchaResponse] = useState(null);

  const handleChangeInput = ({ target }) => {
    const { value, error } = target;
    if (Number(value)) {
      setUsername({ dialCode: "+91", phoneNumber: value });
      if (!error) setValidationError(false);
    } else if (typeof value === "object") {
      if (!value.phoneNumber || Number(value.phoneNumber)) {
        setUsername(value);
        if (!error) setValidationError(false);
      } else setUsername(value.phoneNumber);
    } else {
      if (!emailPattern.test(value)) {
        setValidationError(true);
      } else setValidationError(false);
      setUsername(value);
    }
  };

  const handlePhoneChange = (phoneData) => {
    if (phoneData?.error) {
      setValidationError(true);
    }
    handleChangeInput({
      target: {
        name: "username",
        error: phoneData.error,
        value: phoneData,
      },
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    const url =
      window.location.host.includes("zopping") &&
      !window.location.host.includes("staging")
        ? "https://zopping.com/api/account-service/reset-password"
        : "https://staging.zopping.com/api/account-service/reset-password";

    const formData = new FormData();
    let formDataUsername = username;
    if (typeof username === "object") {
      formDataUsername = `${username.dialCode}${username.phoneNumber}`;
    }
    formData.append("username", formDataUsername);
    formData.append("g-recaptcha-response", captchaResponse);

    setSubmitting(true);

    if (username) {
      window
        .fetch(url, {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then((response) => {
          if (response.code === 200 || response.code === 201) {
            setSuccess(true);
            setSubmitting(false);
            window.gtag("event", "button_click", {
              category: "Button",
              page: "Forgot Password",
              label: "Submit",
            });
          } else {
            console.error(response);
            const errorMsg =
              response?.message?.split?.(":")?.[1] ||
              response?.message ||
              "Some error occurred";
            setError(errorMsg);
            setSubmitting(false);
          }
        });
    }
  };

  const handleCaptcha = (captcha) => {
    setCaptchaResponse(captcha);
  };

  return (
    <div className="login-form-container">
      <div className="form-close-wrp" onClick={close}>
        <img src={Cross} alt="close form" />
      </div>
      {!success ? (
        <>
          <h1 className="login-form-heading">
            <Message dictKey="forgot.password" />
          </h1>
          <p className="login-form-subheading">
            <Message dictKey="zopping.loginForm.subheading" />
          </p>
          <hr className="login-form-hr" />

          {error && <p className="login-form-error">{error}</p>}
          <form onSubmit={handleResetPassword}>
            <label className="login-form-label" htmlFor="username">
              <Message dictKey="heroFormInputPlaceholder" />*
            </label>
            {typeof username === "object" ? (
              <PhoneNumber
                autoFocus
                {...username}
                handleAddPhoneData={handlePhoneChange}
                isDirectionRTL={isDirectionRTL}
              />
            ) : (
              <Input
                autoFocus
                className="login-form-input"
                type="text"
                name="username"
                placeholderKey="loginForm.username.placeholder"
                value={username}
                title={EMAIL_ERR_MSG}
                onChange={handleChangeInput}
                required
              />
            )}
            <div className="captcha-field">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={CAPTCHA_SITE_KEY}
                onChange={handleCaptcha}
                onExpired={() => handleCaptcha(null)}
              />
            </div>
            {validationError && !(typeof username === "object") && (
              <div className="signup-button-error">
                <Message dictKey={"email.error.msg"} />
              </div>
            )}
            <button
              className="login-btn"
              type="submit"
              disabled={
                !username || submitting || validationError || !captchaResponse
              }
            >
              {submitting ? (
                <Message dictKey="submitting" />
              ) : (
                <Message dictKey="submit.text" />
              )}
            </button>
          </form>

          <div className="login-form-create-account">
            <Message dictKey="already.have.account" />? 
            <span
              onClick={() => {
                close();
                showLogin();
                window.gtag("event", "button_click", {
                  category: "Link",
                  page: "Forgot Password",
                  label: "Login",
                });
              }}
              className="create-account-link"
            >
              <Message dictKey="signin" />
            </span>
          </div>
        </>
      ) : (
        <div className="text-center">
          <Message dictKey="resetPassword.successMessage" />
          <div
            onClick={() => {
              close();
              showLogin();
            }}
          >
            <Message dictKey="resetPassword.loginLinkText" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;
