/* eslint-disable react/jsx-no-literals */
import React from "react";

const ClearIcon = ({ ...props }) => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <defs />
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Artboard'>
        <g id='Group-33'>
          <rect id='Rectangle-23' x='0' y='0' width='24' height='24' />
          <g
            id='Group-32'
            transform='translate(11.500000, 12.500000) rotate(-315.000000) translate(-11.500000, -12.500000) translate(4.000000, 4.000000)'
            stroke='#323232'
            strokeLinecap='square'
          >
            <path
              d='M7.21105897,0.693427085 L7.21105897,15.948823'
              id='Line-6'
            />
            <path d='M0,8.58736839 L14.4199214,8.32112502' id='Line-6-Copy' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ClearIcon;
