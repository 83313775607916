/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import twemoji from "twemoji";
import { DropDownArrow } from "../../SVG/SvgIcon";
import PopUp from "../PopUp";
import "./flagEmojiStyle.css";

export default class Emoji extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
    this.wrapperRef = React.createRef();
  }

  handleOnToggle = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();

    if (
      this.wrapperRef?.current &&
      !this.wrapperRef?.current.contains(e.target)
    ) {
      this.setState((state) => ({
        toggle: !state.toggle,
      }));
    }
  };

  handleOnClose = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    if (this.state.toggle) {
      this.setState(() => ({
        toggle: false,
      }));
    }
  };

  render() {
    const { emoji, phoneData, phoneDataName, isDirectionRTL } = this.props;
    return (
      <div
        className={`emoji-bubble-wrp ${
          isDirectionRTL && "emoji-bubble-wrp-rtl"
        }`}
        onClick={this.handleOnToggle}
      >
        <div
          className="emoji"
          dangerouslySetInnerHTML={{
            __html: twemoji.parse(emoji, {
              base: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/",
            }),
          }}
        />
        <div className="bubble-up">
          <DropDownArrow />
          <PopUp
            phoneData={phoneData}
            phoneDataName={phoneDataName}
            handleOnToggle={this.handleOnToggle}
            active={this.state.toggle}
            inputRef={this.wrapperRef}
            handleSetDialCodeAndCountryCode={
              this.props.handleSetDialCodeAndCountryCode
            }
            isDirectionRTL={isDirectionRTL}
          />
        </div>
      </div>
    );
  }
}
